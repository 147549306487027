import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';

// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { DRAWER_WIDTH, APPBAR_MOBILE, APPBAR_DESKTOP} from 'src/constants/theme'
import Iconify from '../../components/Iconify';
import Triangle from '../../assets/images/triangle.png';
import Man from '../../assets/images/man.png';
import { IsCorporate } from 'src/helpers/RouteHelper';
//

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

Sidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const getImage = (name) => <img src={name} width={20} height={20} />;

export default function Sidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const [isAdmin, setIsAdmin] = useState(IsCorporate());
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    setIsAdmin(IsCorporate());
  }, [pathname]);

  const navConfig = [
    {
      title: 'dashboard',
      path: '/dashboard',
      icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
      title: 'user',
      path: `${isAdmin? '/corp-dashboard': '/user'}`,
      icon: getIcon('eva:people-fill'),
    },
    // {
    //   title: 'role',
    //   path: '/role',
    //   icon: getIcon('eva:lock-fill'),
    // },
    {
      title: 'Reported Crimes',
      path: '/reports',
      icon: getIcon('eva:lock-fill'),
    },
    {
      title: 'Corporates/Groups',
      path: '/Corporate',
      icon: getImage(Triangle),
    },
    {
      title: 'Public Accessibility',
      path: '/public-accessiblity',
      icon: getImage(Man),
    },
  ];

  const filteredNavConfig = isAdmin 
    ? navConfig.filter(item => item.title === 'user' || item.title === 'Reported Crimes')
    : navConfig;

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      {/* <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box> */}

      <NavSection navConfig={filteredNavConfig} />

    </Scrollbar>
  );

  return (
    <RootStyle>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>

        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
              // marginTop:APPBAR_DESKTOP,
              left: 'initial',
              marginTop: `${APPBAR_DESKTOP + 10}px`,
            },
          }}
        >
          {renderContent}
        </Drawer>
    </RootStyle>
  );
}
