import React, { useEffect, useMemo } from 'react';
import { WeaponChoices } from "src/constants/showWeapons";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { isArray } from 'lodash';

const { capitalize } = require("./string");

const various_choices1 = ["crime occured at ATM" ,"i believe crime to be drug-related","i believe crime to be gang-related" ,"Arson was involed","Does not apply"," Vandalism was involed" ,"social unrest","Bombs were involved"]


const CrimeDetails=(dataPrime,index,vehicle_theft_choices,various_choices,role_id,roleid)=>{
  //const values2 = useSelector(state=> state.reportRegister.nearbyData);
  
    let {date_time,user,location,latitude,longitude,perpetrators,perpetrators_des,bribePaid,illegal_mining,poaching,animals_name,shoplifting,bribery,is_bribery_paid,weapons,fully_auto_weapons,semi_auto_weapons,knife_weapons,other_weapons,rape,rape_people,murders,murders_people,farm_murder,victim_name,assault,assault_people,vehicle_theft,vehicle_colour,vehicle_make,vehicle_model,vehicle_year,burglary,burglary_type,robbery,robbery_type,kidnapping,kidnapping_people,various,police_reporting,reported_to_the_police,police_case_num,description,fully_auto_weapons_count,knife_weapons_count,other_weapons_count,semi_auto_weapons_count,is_drug_related,report_images}=dataPrime;
    const utcDate = new Date(date_time);
    const mediaData = report_images?.[0]?.path ?? "No media available";
    const localDateString = utcDate?.toLocaleString();
    const profile = JSON.parse(localStorage.getItem("profile"))
    const various_choices1 = ["Crime occurred at ATM",`I believe this crime to be drug-related  ${is_drug_related === 2 ? "(Perpetrator(s) buying/selling drugs)" : is_drug_related === 3 ? "(Perpetrator(s) under the influence of drugs/alcohol)" : ""}`,"Does not apply","I believe this crime to be gang-related" ,"Arson was involved","Vandalism was involved" ,"Social unrest","Bombs were involved"]
    const cleanedString1 = JSON.stringify(various)?.replace(/"|\[|\]/g, '');
    const cleanedString2 = cleanedString1?.replace(/,+/g, ',');
    const cleanedArray1 = cleanedString2?.split(',').map(Number);
    const cleanedArray = cleanedArray1?.filter(element => element !== 2);
    const uniqueSet = new Set(cleanedArray);
    const uniqueArray = Array.from(uniqueSet);
 
    const mappedChoices = uniqueArray.map(index => various_choices1[index]);
    const resultString = mappedChoices.join('<br/>');

    if(isArray(bribery)){
      
      bribery=bribery?.split(',').map(Number);
    }
    

   

    function isBase64Url(str) {
      // Base64Url encoding replaces + and / with - and _ respectively, and removes trailing =
      const base64urlPattern = /^[A-Za-z0-9\-_]+$/;
      return base64urlPattern.test(str);
    }
    
    function isNotJWT(token) {
      if(!token) return true;
      // JWT must have exactly two periods
      const parts = token.split('.');
      if (parts.length !== 3) {
        return true;
      }
    
      // Check if each part is Base64Url encoded
      for (let part of parts) {
        if (!isBase64Url(part)) {
          return true;
        }
      }
    
      return false;
    }

    const crimeDetails =  [
        { firstCol: 'Date/Time Occurred:', secondCol:  
          (
            <div>
              <p>{localDateString}</p>
              {user && user.corporate !== null && user.corporate !== undefined ? (
                <>
                  <img
                    src={user.corporate.logo}
                    style={{ height: "30px", width: "30px", border: "2px solid #333", borderRadius: "50%"}}
                    alt="No Data Available"
                  />
                  {user.corporate.is_verify === 1 ? <CheckBoxIcon style={{ height: "30px", width: "30px", color: "#29C250" }} /> : ''}
                </>
              ) : ''
              }
            </div>
          ),
        },
        { firstCol: 'Address:', secondCol: (()=>{ return (
          <div>
            <p>{location}</p><br></br>
            <p>{latitude}</p>
            <p>{longitude}</p>
          </div>
        ); 
        })() },
        
        { firstCol: 'Description:', secondCol: (()=>{
          if((description!==null) && ((profile && profile.data.role_id===1)  || (role_id===roleid))){
            return `${isNotJWT(description)?description:jwtDecode(description).description}`
          }
        })() },
        { firstCol: 'Perpetrators:', secondCol: 
        (()=>{
          if(perpetrators&&perpetrators!=null&&perpetrators!=="-1"&&perpetrators!=="0"){
            let pedes = '';
            if((perpetrators_des!==null) && ((profile && profile.data.role_id===1) || (role_id===roleid))){
              pedes = `, ${isNotJWT(perpetrators_des)?perpetrators_des:jwtDecode(perpetrators_des).perpetrators_des}`;
            }
            return `(${perpetrators}) ${pedes}`
          }
          if(perpetrators&&perpetrators!=null&&perpetrators=="-1"&&perpetrators!=="0"){
            let pedes = '';
            if((perpetrators_des!==null) && ((profile && profile.data.role_id===1) || (role_id===roleid))){
              pedes = `${isNotJWT(perpetrators_des)?perpetrators_des:jwtDecode(perpetrators_des).perpetrators_des}`;
            }
            return `${pedes}`
          }

        })() },

        { firstCol: 'Weapons:', secondCol: (()=>{
          if(weapons && weapons===1 && weapons!=null && (fully_auto_weapons === 1 || semi_auto_weapons ===1 || knife_weapons ===1 || other_weapons ===1 )){
            const results = [];

    if (fully_auto_weapons === 1 && fully_auto_weapons !== null && fully_auto_weapons_count > 0) {
        results.push(`Fully Automatic: ${WeaponChoices[fully_auto_weapons]} (${fully_auto_weapons_count})`);
    }
    if (semi_auto_weapons === 1 && semi_auto_weapons !== null && semi_auto_weapons_count > 0) {
        results.push(`Semi Automatic: ${WeaponChoices[semi_auto_weapons]} (${semi_auto_weapons_count})`);
    }
    if (knife_weapons === 1 && knife_weapons !== null && knife_weapons_count > 0) {
        results.push(`Knife: ${WeaponChoices[knife_weapons]} (${knife_weapons_count})`);
    }
    if (other_weapons === 1 && other_weapons !== null && other_weapons_count > 0) {
        results.push(`Other: ${WeaponChoices[other_weapons]} (${other_weapons_count})`);
    }

    return (
        <div>
            {results.map((result, index) => (
                <div key={index}>{result}<br /></div>
            ))}
        </div>
    );
          }
        })() },

        { firstCol: 'Rape:', secondCol:(()=>{
          if(rape===0){
            return null
          }
          if(rape===1){
            return `Attempted Rape (${rape_people})`
          }
          if(rape===2){
            return `Rape (${rape_people})`
          }
        })() },
        { firstCol: 'Murder:', secondCol:(()=>{
          if(murders === 1) {
            return (
              <div>
                {`Murder (${murders_people})`}
               <br/>
                {farm_murder === 1 && `It was a farm murder`}<br />
                {victim_name && (
                  <span>{'Name(s) of victim(s) '}<br/>{ victim_name.replace(/"/g, '')}</span>
                )}
              </div>
            );
          }
        } )() },
        { firstCol: 'Assault:', secondCol:(()=>{
          switch(assault){
            case 0:return ``
            case 1:return `Assault (${assault_people})`
            default:return null;//`No`
          }
        })() },
        { firstCol: 'Vehicle related:', secondCol:(()=>{
          if(vehicle_theft==4 || vehicle_theft=="4"){
            return null;
          }else{
            return( <div>
              {capitalize(`${vehicle_theft_choices[vehicle_theft]}`)}

            </div>);
          }
        })() },
        { firstCol: 'Vehicle Type:', secondCol:(()=>{
          if(vehicle_theft === 4 && vehicle_theft === "4"){
            return null;
          } else {
            return (vehicle_year || vehicle_colour || vehicle_make || vehicle_model) 
              ? capitalize(`${[vehicle_year,vehicle_colour,vehicle_make,vehicle_model].filter((el)=>el)?.join(" ")} Vehicle`)
              : "Vehicle Description Unavailable"
          }
        })() },

        { firstCol: 'Burglary:', secondCol:(()=>{
          if(burglary == 0){
            return null;
          }
          const typeString = Array.isArray(burglary_type) ? burglary_type.join(', ') : (burglary_type || "");
          if(burglary == 1){
            return <div>{`Attempted Burglary ${typeString} `}<br/></div>
          }
          if(burglary == 2){
            return <div>{`Burglary ${typeString} `}<br/></div>;
          }
          if(burglary == 3){
            return <div>{`Attempted Shoplifting ${typeString} `}<br/></div>
          }
          if(burglary == 4){
            return <div>{`Attempted Robbery ${typeString} `}<br/></div>;
          }
        })() },

        { firstCol: 'Robbery:', secondCol:(()=>{
          if(robbery == 0){
            return null;
          }
          const typeString = (typeof robbery_type === 'string') ? robbery_type?.replace(/,/g, ', ') : "";
          if(robbery == 1){
            return <div>{`Attempted Robbery of ${typeString} `}<br/></div>
          }
          else if(robbery == 2){
            return <div>{`Robbery of ${typeString} `}<br/></div>
          }
        })() },
        { firstCol: 'Kidnapping:', secondCol:(()=>{
          if(kidnapping===0){
            return null;
          }
          if(kidnapping===1){
            return <div>{`Attempted Kidnapping of ${kidnapping_people}`}<br/></div>
          }
          if(kidnapping===2){
            return <div>{`Kidnapping of ${kidnapping_people}`}</div>
          }
        })() },
        
        { firstCol: 'Bribery:', secondCol: (() => {
          if (bribery?.includes(0)) {
            return null;
          }
        
          const options = [];
          if (bribery?.includes(1)) {
            options.push(
              <div key="1">
                Bribe request by police officer 
               
              </div>
            );
          }
          if (bribery?.includes(2)) {
            options.push(
              <div key="2">
                Bribe request by civil servant 
               
              </div>
            );
          }
          if (bribery?.includes(3)) {
            options.push(
              <div key="3">
                Bribe request by politician 
                
              </div>
            );
          }
          if (bribery?.includes(4)) {
            options.push(
              <div key="4">
                Bribe offered by member of general public 
                
              </div>
            );
          }
       
          return <>{options}</>;
        })() },
        { firstCol: 'Bribe Paid:', secondCol:(()=>{
         
          if(is_bribery_paid===1){
            return (
              <div>Yes
                </div>
            )
          }
          if(is_bribery_paid===2){
            return (
              <div>No
                </div>
            )
          }
          if(is_bribery_paid===0){
            return (
              <div> Unknown
                </div>
            )
          }
          else{
            
              return null;
            
          }
        })() },
        { firstCol: 'Shoplifting:', secondCol:(()=>{
          if(shoplifting===0){
            return null;
          }
          if(shoplifting===1){
            return `Attempted Shoplifting`
          }
          if(shoplifting===2){
            return `Shoplifting`;
          }
        })() },
        { firstCol: 'Illegal Mining:', secondCol:(()=>{
          if(illegal_mining===0){
            return null;
          }
          if(illegal_mining===1){
            return `Attempted Illegal Mining`
          }
          if(illegal_mining===2){
            return `Yes`;
          }
        })() },
        { firstCol: 'Poaching:', secondCol:(()=>{
          if(poaching === 0){
            return null;
          }
          const animalsString = animals_name ? animals_name?.replace(/,/g, ', ') : "";
          if(poaching === 1){
            return <div>{`Attempted Poaching of ${animalsString} `}<br/></div>
          }
          if(poaching === 2){
            return <div>{`Poaching of ${animalsString} `}<br/></div>
          }
        })() },
        { firstCol: 'Reason for Crime:', secondCol: 
          (()=>{
            if(cleanedArray && JSON.stringify(various)?.replace(/"|\[|\]/g, '').length>0 && resultString.length > 0){
              return <div dangerouslySetInnerHTML={{ __html: resultString }} />
            }else{
              return null;
            }
          })()
        },
        { firstCol: 'Police Visited Crime Scene:', secondCol: (police_reporting==1?"Yes":police_reporting==0?"Unknown":"No") },
        { firstCol: 'Formally reported to the police:', secondCol: (reported_to_the_police==1?"Yes":reported_to_the_police==0?"Unknown":"No") },
        { firstCol: 'Police Case Number:', secondCol: police_case_num?police_case_num:null },
        { firstCol: 'Media:', secondCol: (()=>{if((profile && profile.data.role_id===1) || (role_id===roleid)){return mediaData} else return null})()},
      ];
      // useEffect(() => {
      //   console.log("CrimeDetails re-rendered with new props");
      // }, [crimeDetails]);
    
      return crimeDetails;
}
export default CrimeDetails;